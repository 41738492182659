jQuery( function ($) {

    $('section.image_slider').each(function () {
        let slider_speed = 300;
        let $section = $(this);
        let $slider = $( '.slick-slider', $section );
        let $slides = $( '.slick-slide', $slider );
        let num_slides = $slides.length;

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            speed: slider_speed,
            arrows: true,
            infinite: true,
            draggable: true,
            centerMode: true,
            mobileFirst: true,
            prevArrow: $( '.previous-btn', $section ),
            nextArrow: $( '.next-btn', $section ),
        });

        // grabbed after slick init to include .slick-cloned
        let $all_slides = $( '.slick-slide', $slider );

        // we need to add a custom class to the slide that is about to be centered
        // the issue has to do with slick's cloning and shifting of slides
        $slider.on( 'beforeChange', function ( e, slick, current, next ) {
            $all_slides.each( function () {
                let slick_index = parseInt( $(this).attr( 'data-slick-index' ) );
                let index_mod = slick_index % num_slides;
                let actual_slide_index = ( num_slides + index_mod ) % num_slides;
                $(this).toggleClass( 'actual-centered-item', actual_slide_index == next );
            });
        });

    });

});