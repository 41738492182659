( function ( $ ) {


    let $header;
    let $body;
    let $menu_wrap;
    let $menu_toggle;
    let $submenus;
    let $sub_menu_toggles;
    let $window = $(window);
    let $non_mobile_menu_tabbables;

    let transparent_header = false;

    function set_element_refs () {
        $header = $( 'header.site-header' );
        $body = $( document.body );
        $menu_wrap = $( '.menu-wrap', $header );
        $menu_toggle = $( 'button.menu-toggle', $header );
        $submenus = $( '#primary-menu-container > ul > li > .sub-menu' );
        $sub_menu_toggles = $( 'button.sub-menu-toggle', $menu_wrap );
        $non_mobile_menu_tabbables = $( '#skip-to-content, .header-logo-link, main, footer' );
    }


    function is_mobile () {
        return $menu_toggle.is( ':visible' );
    }



    function init_header_style () {
        // sets initial styles and listeners
        set_body_padding();
        $window.on( 'breakpoint_changed', set_body_padding );
        $header.addClass( 'fixed' );
        $menu_toggle.css( 'margin-right', ThemeJS.Functions.get_scrollbar_width() );
    }


    function set_body_padding () {
        let spacing = transparent_header ? 0 : $header.height();
        $body.css({
            paddingTop: `${ spacing }px`
        });
    }

    function set_menu_wrap_transition () {
        $menu_wrap.toggleClass( 'has-transition', !ThemeJS.Media_Queries.is_at_least( 'xl' ) );
    }


    $( function () {
        set_element_refs();
        init_header_style();
        set_menu_wrap_transition();
        $window.on( 'breakpoint_changed', function ( e, to, from ) {
            if ( [ to, from ].includes( 'lg' ) && [ to, from ].includes( 'xl' ) ) {
                $non_mobile_menu_tabbables.css( 'visibility', 'visible' );
                $body.removeClass( 'menu-open' );
            }
            set_menu_wrap_transition();
        });

        $menu_toggle.click( function () {
            $body.toggleClass( 'menu-open' );
            if ( $body.hasClass( 'menu-open' ) ) {
                $( 'a', $menu_wrap ).first().focus();
                setTimeout( function () {
                    $non_mobile_menu_tabbables.css( 'visibility', 'hidden' );
                }, 300 );
            } else {
                $non_mobile_menu_tabbables.css( 'visibility', 'visible' );
            }
        });
        if ( $sub_menu_toggles.length ) {
            $sub_menu_toggles.click( function ( e ) {
                e.preventDefault();
                e.stopPropagation();
                let $parent_li = $(this).closest( 'li' );
                let $submenu = $( '> ul.sub-menu', $parent_li );
                let was_visible = $submenu.is( ':visible' );
                $submenu.slideToggle( 300 );
                $parent_li.attr( 'aria-expanded', !was_visible );
            });
        }
    });


})( jQuery );