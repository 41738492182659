jQuery( function ($) {

    $('.slider_banner').each(function () {
        let $section = $(this);
        let $slider = $( '.slick-slider', $section );
        let $live_section = $( '.slider-text-live', $section );

        $slider.slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: true,
            infinite: true,
            prevArrow: $( '.previous-btn', $section ),
            nextArrow: $( '.next-btn', $section ),
        });

        // these don't have slick-arrow class until after init
        let $slider_btns = $( '.slick-arrow', $section );

        $slider.on( 'beforeChange', function ( e, slick, current, next ) {
            $slider_btns
                .removeClass( slick.$slides.filter( `[data-slick-index=${current}]` ).data().btn_text_class )
                .addClass( slick.$slides.filter( `[data-slick-index=${next}]` ).data().btn_text_class );
        });

        $slider.on( 'afterChange', function ( e, slick, current ) {
            let aria_live = this.contains( document.activeElement ) ? 'polite' : 'off';
            $live_section.attr( 'aria-live', aria_live );
            $live_section.html( `Active slide title: ${ $( '.slide-title', slick.$slides.filter( '.slick-active' ) ).html() }` );
        });

    });

});