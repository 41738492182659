jQuery( function ($) {

    $('section.image_ticker').each(function () {
        const $section = $(this);
        const $slider = $( '.slider', $section );
        const num_slides = $( '.slide', $slider ).length;

        const args = {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            infinite: true,
            draggable: true,
            centerMode: true,
            mobileFirst: true,
            accessibility: false,
            arrows: true,
            prevArrow: $( '.previous-btn', $section ),
            nextArrow: $( '.next-btn', $section ),
            responsive: [
                {
                    breakpoint: ThemeJS.Variables.Screen_Sizes.sm,
                    settings: num_slides < 3 ? 'unslick' : {
                      slidesToShow: 3,
                    }
                },
                {
                    breakpoint: ThemeJS.Variables.Screen_Sizes.lg,
                    settings: num_slides < 5 ? 'unslick' : {
                      slidesToShow: 5,
                    }
                },
            ]
        };

        $slider.slick( args );
        $( window ).on( 'breakpoint_changed', function () {
            $slider.slick( args );
        });

    });

});