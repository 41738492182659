jQuery( function ($) {

    $( document ).on( 'click', 'section.initiatives article button.expand-toggle', function () {
        let $content = $( `#${ $(this).attr( 'aria-controls' ) }` );
        if ( $content.length ) {
            let closing = $content.is(':visible');
            $content.slideToggle();
            $content.attr( 'aria-hidden', closing ? 'true' : 'false' );
            // not using $(this) because there are mobile/desktop copies
            $( 'button.expand-toggle', $(this).closest( 'article') ).attr( 'aria-expanded', closing ? 'false' : 'true' );
        }
    });

});